import { selector } from "recoil";
import { apiProdutosState } from "../atoms/apiProdutosState";
import { removeDiacritics } from "../stringUtils";

export const menuState = selector({
  key: "menuState",
  get: ({ get }) => {
    const produtos = get(apiProdutosState);
    const categorias = {};

    produtos.forEach((produto) => {
      const catName = produto.CATEGORIA;
      let subcatId = "";
      if (produto.SUBCATEGORIA !== "") {
        subcatId = produto.CATEGORIA + produto.SUBCATEGORIA;
      }
      if (!(catName in categorias)) {
        categorias[catName] = {
          nome: produto.CATEGORIA,
          key: `CAT${produto.CATEGORIA}`,
          id: `CAT${produto.CATEGORIA}`,
          urlname: removeDiacritics(produto.CATEGORIA).toLowerCase().replace(/ /g, ''),
          subcats: {},
          link_type: "",
          tipo: "cat",
        }
      }
      if (subcatId && !(subcatId in categorias[catName].subcats)) {
        categorias[catName].subcats[subcatId] = {
          nome: produto.SUBCATEGORIA,
          tipo: "subcat",
          key: `SUBCAT${produto.CATEGORIA}${produto.SUBCATEGORIA}`,
          id: `SUBCAT${produto.CATEGORIA}${produto.SUBCATEGORIA}`,
          urlname: produto.URLNAME,
        }
      }
    })

    const categoriaList = [];
    for (const catKey in categorias) {
      const cat = categorias[catKey];
      const subcatList = [];
      if (Object.keys(cat.subcats).length > 0) {
        // push a "Tudo de ..." link
        subcatList.push({
          nome: `Tudo de ${categorias[catKey].nome}`,
          tipo: "cat",
          key: `CAT${categorias[catKey].nome}`,
          id: `CAT${categorias[catKey].nome}`,
          urlname: removeDiacritics(categorias[catKey].nome).toLowerCase().replace(/ /g, ''),
        })
        categorias[catKey].link_type = "open_submenu";
      } else {
        categorias[catKey].link_type = "link";
      }
      for (const subcatId in cat.subcats) {
        subcatList.push(categorias[catKey].subcats[subcatId]);
      }

      const formattedCat = { ...cat };
      formattedCat.subcats = subcatList;
      categoriaList.push(formattedCat);
    }
    return categoriaList;
  }
});