import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useRecoilValue } from "recoil";
import { apiProdutosState } from "./atoms/apiProdutosState";
import { TableContainer, TableHead } from '@mui/material';
import { removeDiacritics } from './stringUtils';

const UniaoTabela = () => {
  const produtos = useRecoilValue(apiProdutosState);

  const sortProdutos = (produtos) => {
    const sorted = [...produtos];

    const compare = (a, b) => {
      if (a.CODIGO.toString() < b.CODIGO.toString()) {
        return -1;
      }
      if (a.CODIGO.toString() > b.CODIGO.toString()) {
        return 1;
      }
      return 0;
    }
    sorted.sort(compare);
    return sorted;
  }

  const removeDot = (s) => {
    if (typeof s === 'string' && s.includes(".")) {
      return s.replace('.', '');
    }
    return s;
  }

  const goToDownload = () => {
    const dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    if (month < 10) {
      month = '0' + month;
    }
    let day = dateObj.getUTCDate();
    if (day < 10) {
      day = '0' + day;
    }
    const year = dateObj.getUTCFullYear();

    const todayDate = year + "-" + month + "-" + day;

    const filename = 'uniao_planilha_' + todayDate + '.csv';
    const produtoRows = sortProdutos(produtos).map((produto) => [
      produto.CODIGO,
      produto.CV,
      removeDot(produto.DISPONIVEL),
      removeDot(produto.RESERVADO),
      produto.PRODUTO,
      produto.UNIDADE,
      produto.CAIXAPEQ,
      produto.CAIXAGRANDE,
      produto.DIMENSAO,
      produto.NCM,
    ]);

    const rows = [[
      "Código",
      "CV",
      "Disponível",
      "Reservado",
      "Nome",
      "Unid.",
      "Cx. peq.",
      "Cx. gde.",
      "Dimensões",
      "NCM",
    ]].concat(produtoRows);

    var processRow = function (row) {
      var finalVal = '';
      for (var j = 0; j < row.length; j++) {
        var innerValue = row[j] === null ? '' : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        };
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0)
          result = '"' + result + '"';
        if (j > 0)
          finalVal += ',';
        finalVal += result;
      }
      return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
      csvFile += removeDiacritics(processRow(rows[i])).toUpperCase();
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  return (
    <Container
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', my: 1 }}
    >
      <Container
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 2 }}
      >

        <Button
          variant="outlined"
          onClick={goToDownload}
          sx={{ mt: '1rem', maxWidth: '10rem' }}
        >
          Download
        </Button>
      </Container>

      <TableContainer sx={{ width: '100%', maxHeight: 'calc(100vh - 14rem)', padding: 0, margin: 0 }}>
        <Table stickyHeader sx={{ mb: 6, width: "max-content" }}>
          <TableHead>
            <TableRow key="tableHead">
              <TableCell size="small" sx={{ position: "sticky", left: 0, background: "white", zIndex: 3 }}>Código</TableCell>
              <TableCell size="small">CV</TableCell>
              <TableCell size="small">Disp. / Res.</TableCell>
              <TableCell size="small">Nome</TableCell>
              <TableCell size="small">Unid.</TableCell>
              <TableCell size="small">Cx. peq. / gde.</TableCell>
              <TableCell size="small">Dimensões</TableCell>
              <TableCell size="small">NCM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortProdutos(produtos).map((produto) => (
              <TableRow key={produto.CODIGO}>
                <TableCell size="small" sx={{ position: "sticky", left: 0, background: "white" }}>{produto.CODIGO}</TableCell>
                <TableCell size="small">{produto.CV}</TableCell>
                <TableCell size="small">{produto.DISPONIVEL} / {produto.RESERVADO}</TableCell>
                <TableCell size="small">{produto.PRODUTO}</TableCell>
                <TableCell size="small">{produto.UNIDADE}</TableCell>
                <TableCell size="small">{produto.CAIXAPEQ} / {produto.CAIXAGRANDE}</TableCell>
                <TableCell size="small">{produto.DIMENSAO}</TableCell>
                <TableCell size="small">{produto.NCM}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default UniaoTabela;
