import UniaoCategoryIcon from "./UniaoCategoryIcon";
import { useRecoilValue } from "recoil";
import { apiProdutosState } from "./atoms/apiProdutosState";
import Grid from '@mui/material/Grid';
import { Typography, useMediaQuery } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";

const UniaoHomepageIcons = () => {
  const produtos = useRecoilValue(apiProdutosState);

  const isLargeScreen = useMediaQuery('(min-width: 600px');

  const banners = [
    {
      name: "Promoção",
      image: "/homepage/banner-promocao.jpg",
      link: "/promocao/"
    },
    {
      name: "Reposição",
      image: "/homepage/banner-reposicao.jpg",
      link: "/reposicao/"
    }
  ];

  const Item = (props) => {
    return (
      <div style={{ width: "100%", height: "300px" }}>
        <Typography
          variant="h6"
        >
          <Link to={props.item.link}>
            <img src={props.item.image} alt={props.item.name} />
          </Link>
        </Typography>
      </div>
    );
  }

  const banner = () => {
    if (isLargeScreen) {
      return (
        <div style={{ width: "calc(100vw - 16.5rem)", maxWidth: "800px" }}>
          <Carousel interval={4500}>
            {banners.map((item, i) => <Item key={i} item={item} />)}
          </Carousel>
        </div>
      );
    } else {
      return <></>;
    }
  }

  if (produtos.length > 0) {
    return (
      <>
        {banner()}
        <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', mb: 8 }}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <UniaoCategoryIcon iconFilename="ic-balanca.svg" name="Balanças" target="balancas" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <UniaoCategoryIcon iconFilename="ic-beleza.svg" name="Beleza" target="beleza" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <UniaoCategoryIcon iconFilename="ic-caneca.svg" name="Canecas" target="canecasecopos" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <UniaoCategoryIcon iconFilename="ic-coqueteleira.svg" name="Coqueteleiras" target="cantisecoqueteleiras" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <UniaoCategoryIcon iconFilename="ic-cozinha.svg" name="Cozinha" target="cozinha" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <UniaoCategoryIcon iconFilename="ic-costura.svg" name="Kits Costura" target="kitscostura" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <UniaoCategoryIcon iconFilename="ic-kit-vinho.svg" name="Kits Vinho" target="kitsvinho" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <UniaoCategoryIcon iconFilename="ic-lanterna.svg" name="Lanternas" target="lanternas" />
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
}

export default UniaoHomepageIcons;