import Container from "@mui/material/Container";
import UniaoProductGrid from "./UniaoProductGrid";
import FilledInput from '@mui/material/FilledInput';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import { useState, useRef, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import FormControl from '@mui/material/FormControl';
import { useRecoilValue } from "recoil";
// import { gridState } from "./atoms/gridState";
import { apiProdutosState } from "./atoms/apiProdutosState";
import { useNavigate, useParams } from "react-router-dom";

const UniaoSearch = () => {
  const stripAccents = (text) => {
    text = text.toLowerCase();
    text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
    return text;
  }

  const [query, setQuery] = useState('');
  const [header, setHeader] = useState('');
  const produtos = useRecoilValue(apiProdutosState);

  const navigate = useNavigate();

  let { urlquery } = useParams();
  if (urlquery === undefined) {
    urlquery = "";
  }
  // const [gridItems, setGridItems] = useState([]);
  let gridItems = [];
  if (urlquery) {
    gridItems = produtos.filter(produto => produto.CHAVEDEBUSCA.includes(stripAccents(urlquery.trim())))
  }

  // const setGrid = useSetRecoilState(gridState);

  const inputRef = useRef(null);

  /*
  useEffect(() => {
    search();
  }, [doSearch, produtos]);
  */

  useEffect(() => {
    console.log("UniaoSearch: useEffect running.");
    inputRef.current.focus();
    if (urlquery) {
      setQuery(urlquery);
    }
  }, [urlquery]);

  const handleOnChange = (event) => {
    setQuery(event.target.value);
  }

  const search = () => {
    console.log("UniaoSearch: search searching " + query);
    if (query.trim() === "") {
      return;
    }
    setHeader("Resultados para " + query);
    // setGridItems(produtos.filter(produto => produto.CHAVEDEBUSCA.includes(stripAccents(query.trim()))));
    window.location.href = window.location.href.replace(/\/[^/]*$/, '/' + query);
  };

  const handleClearClick = () => {
    setQuery("");
    // setGrid([]);
    setHeader("");
    navigate("/busca/");
    inputRef.current.focus();
  }

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  return (
    <form onSubmit={handleSubmit}>
      <Container
        sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 1 }}
      >
        <Typography
          variant="h4"
        >
          Busca
        </Typography>

        <FormControl fullWidth sx={{ m: 1 }} variant="filled">
          <InputLabel htmlFor="searchQuery">Código ou descrição</InputLabel>
          <FilledInput
            id="searchQuery"
            variant="filled"
            value={query}
            onChange={handleOnChange}
            inputRef={inputRef}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="limpar campo"
                  onClick={handleClearClick}
                  onMouseDown={handleClearClick}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <Button
          type="submit"
          variant="outlined"
          onClick={search}
          sx={{ mt: '1rem' }}
        >
          Buscar
        </Button>

        <Typography
          variant="h6"
          sx={{ mt: '1rem' }}
        >
          {header}
        </Typography>
        <UniaoProductGrid gridItems={gridItems} key={query} />
      </Container>
    </form>
  )
}

export default UniaoSearch;