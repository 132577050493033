import { useRecoilValue } from "recoil";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
// import { productModalState } from "./atoms/productModalState";
// import { singleProductState } from "./atoms/singleProductState";
// import { singleProductSpinnerState } from "./atoms/singleProductSpinnerState";
import { apiProdutosState } from "./atoms/apiProdutosState";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import UniaoClienteProdutoInfo from "./UniaoClienteProdutoInfo";

const UniaoProductGrid = (props) => {
  const gridItems = props.gridItems.sort((a, b) => {
    if (a.CODIGO < b.CODIGO) {
      return -1;
    } else if (a.CODIGO > b.CODIGO) {
      return 1;
    } else {
      return 0;
    }
  });

  // const gridItems = useRecoilValue(gridState);

  // let gridItems = [];
  // gridItems = produtos.filter((produto) => produto.URLNAME.startsWith(urlname));

  // const setProductModal = useSetRecoilState(productModalState);
  // const setSingleProduct = useSetRecoilState(singleProductState);
  // const setSingleProductSpinner = useSetRecoilState(singleProductSpinnerState);

  const navigate = useNavigate();

  const handleCardClick = (produto) => {
    console.log("UniaoProductGrid: handleCardClick " + produto.CODIGO);
    // setProductModal(true);
    // setSingleProduct(produto);
    // setSingleProductSpinner(true);
    navigate(`/produtos/${produto.CODIGO}`);
  }

  return (
    <Container sx={{ pt: 1, pb: 12, display: 'flex', justifyContent: 'center', alignContent: 'center' }} maxWidth="xl">
      <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        {gridItems.map((item) => (
          <Grid item key={item.CODIGO} xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <CardMedia
                component="img"
                image={`/thumbs20230320/${item.CODIGO}.jpg`}
                alt="foto"
                onClick={() => handleCardClick(item)}
              />
              <Button
                onClick={() => handleCardClick(item)}
                style={{ width: '12rem' }}
                variant='outlined'
              >
                Ampliar imagem
              </Button>
              <CardContent sx={{ flexGrow: 1 }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  sx={{ mt: '1rem' }}
                >
                  <Typography gutterBottom variant="h5">
                    {item.CODIGO}
                  </Typography>
                  <Typography align="center">
                    {item.PRODUTO}
                  </Typography>
                  <UniaoClienteProdutoInfo item={item} />
                </Box>
              </CardContent>
            </Card>
          </Grid>))}
      </Grid>
    </Container>
  )
}

export default UniaoProductGrid;