import { useEffect } from "react";
import Box from "@mui/material/Box";
import UniaoAppBar from "./UniaoAppBar";
import UniaoDrawer from "./UniaoDrawer";
import { apiProdutosState } from "./atoms/apiProdutosState";
import { useSetRecoilState } from "recoil";
import UniaoWelcome from "./UniaoWelcome";
import UniaoFooter from "./UniaoFooter";
import { loggedInState } from "./atoms/loggedInState";
import { useOutlet } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import UniaoMenuCategories from "./UniaoMenuCategories";

const UniaoSite = () => {
  const setApiProdutosState = useSetRecoilState(apiProdutosState);
  const setLoggedIn = useSetRecoilState(loggedInState);

  const outlet = useOutlet();

  const isLargeScreen = useMediaQuery('(min-width: 600px');

  const drawerWrapper = () => {
    if (isLargeScreen) {
      return <></>
    } else {
      return <UniaoDrawer />
    }
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/userstatus.php`, {
      headers: {
        'uniao-cliente-token': window.localStorage.getItem('uniaoClienteToken')
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log("UniaoSite: useEffect userstatus result: " + JSON.stringify(result));
          setLoggedIn(result.user);
          fetch(`${process.env.REACT_APP_API_URL}/produtos.php`, {
            headers: {
              'uniao-cliente-token': window.localStorage.getItem('uniaoClienteToken')
            }
          })
            .then(res => res.json())
            .then(
              (result) => {
                setApiProdutosState(result.produtos);
              },
              (error) => {
                console.error("UniaoSite: useEffect fetch produtos.php error: " + error);
              }
            )
        },
        (error) => {
          console.error("UniaoSite: useEffect fetch userstatus error: " + error);
        }
      )
  }, []);

  const mainWrapper = () => {
    if (isLargeScreen) {
      return (
        <div style={{ display: "flex" }}>
          <div className="fixed-menu">
            <UniaoMenuCategories />
          </div>
          <div className="main-content" id="mainContent">
            {outlet || <UniaoWelcome />}
            <UniaoFooter />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {outlet || <UniaoWelcome />}
          <UniaoFooter />
        </div>
      );
    }
  }

  return (
    <div>
      <UniaoAppBar />
      {drawerWrapper()}
      <Box
        sx={{ position: "relative", minHeight: 'calc(100vh - 120px)' }}
      >
        {mainWrapper()}
      </Box>
    </div>
  )
}

export default UniaoSite;