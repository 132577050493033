import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { selectedMenuItemState } from "./atoms/selectedMenuItemState";
import { categoryState } from "./atoms/categoryState";
import { drawerState } from "./atoms/drawerState";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { menuState } from "./selectors/menuState";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const UniaoMenuCategories = () => {
  const menu = useRecoilValue(menuState);
  const [selectedMenuItem, setSelectedMenuItem] = useRecoilState(selectedMenuItemState);
  const setCategory = useSetRecoilState(categoryState);
  const setDrawer = useSetRecoilState(drawerState);

  const navigate = useNavigate();

  const openPromocao = () => {
    console.log("UniaoMenu: openPromocao");
    setDrawer(false);
    navigate("/promocao/");
  }

  const openReposicao = () => {
    console.log("UniaoMenu: openReposicao");
    setDrawer(false);
    navigate("/reposicao/");
  }

  const openCat = (item) => () => {
    console.log(`UniaoMenu: openCat ${item.key}`);
    if (selectedMenuItem === item.id) {
      setSelectedMenuItem(0);
    } else {
      setSelectedMenuItem(item.id);
    }
    if (item.link_type === "link") {
      setCategory({
        type: item.tipo,
        id: item.id,
        name: item.nome,
      });
      // loadGrid(item.tipo, item.id);
      navigate(`/categorias/${item.urlname}`);
      setDrawer(false);
    }
  }

  const openSubcat = (item) => (event) => {
    event.stopPropagation(); // avoid opening cat
    console.log(`UniaoMenu: openSubcat ${item.key}`);
    setCategory({
      type: item.tipo,
      id: item.id,
      name: item.nome,
    });
    // loadGrid(item.tipo, item.id);
    navigate(`/categorias/${item.urlname}`);
    setDrawer(false);
  }

  const defaultCategories = () => {
    if (menu.length > 0) {
      return (
        <>
          <Box
            sx={{ background: 'linear-gradient(90deg, rgba(220,220,220,1) 0%, rgba(255,255,255,1) 20%, rgba(191,191,191,1) 100%)', pl: '1rem', py: '0.8rem;', borderTop: 1, borderColor: '#dcdcdc', display: 'flex', alignItems: 'center' }}
            onClick={openPromocao}
          >
            <LocalFireDepartmentIcon />
            <Typography
              sx={{ pl: '0.6rem' }}
            >
              Promoção
            </Typography>
          </Box>
          <Box
            sx={{ background: 'linear-gradient(90deg, rgba(220,220,220,1) 0%, rgba(255,255,255,1) 20%, rgba(191,191,191,1) 100%)', pl: '1rem', py: '0.8rem;', borderTop: 1, borderColor: '#dcdcdc', display: 'flex', alignItems: 'center' }}
            onClick={openReposicao}
          >
            <AutorenewIcon />
            <Typography
              sx={{ pl: '0.6rem' }}
            >
              Reposição
            </Typography>
          </Box>
        </>
      );
    } else {
      return <></>;
    }
  }

  const categoryAccordions = () => {
    return menu.map((menuItem) => (
      <Accordion
        key={menuItem.key}
        onClick={openCat(menuItem)}
        expanded={menuItem.id === selectedMenuItem}
        sx={{ background: "linear-gradient(90deg, rgba(220,220,220,1) 0%, rgba(255,255,255,1) 20%, rgba(191,191,191,1) 100%)" }}
      >
        <AccordionSummary
          expandIcon={menuItem.link_type === "open_submenu" && menuItem.id > 0 && <ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Typography>
            {menuItem.nome}
          </Typography>
        </AccordionSummary>
        {menuItem.subcats && menuItem.subcats.map((subcat) => (
          <AccordionDetails
            key={subcat.key}
            onClick={openSubcat(subcat)}
          >
            <Typography
              sx={{ ml: '1.5rem', color: "#676767" }}
            >
              {subcat.nome}
            </Typography>
          </AccordionDetails>
        ))}
      </Accordion>
    ));
  }

  return (
    <div style={{ background: "linear-gradient(90deg, rgba(220,220,220,1) 0%, rgba(255,255,255,1) 20%, rgba(191,191,191,1) 100%)" }}>
      {defaultCategories()}
      {categoryAccordions()}
    </div>
  );
}

export default UniaoMenuCategories;