import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { apiProdutosState } from "./atoms/apiProdutosState";
import { useEffect } from "react";
import UniaoProductGrid from "./UniaoProductGrid";

const UniaoCategoryGrid = () => {
  const { urlname } = useParams();
  const produtos = useRecoilValue(apiProdutosState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [urlname]);

  let gridItems = [];
  gridItems = produtos.filter((produto) => produto.URLNAME.startsWith(urlname));

  return <UniaoProductGrid gridItems={gridItems} />
}

export default UniaoCategoryGrid;