import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import { useSetRecoilState } from "recoil";
import { drawerState } from "./atoms/drawerState";
import UniaoHomepageIcons from "./UniaoHomepageIcons";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { loggedInState } from "./atoms/loggedInState";
import { useRecoilValue } from "recoil";

const UniaoWelcome = () => {
  const setDrawer = useSetRecoilState(drawerState);
  const loggedIn = useRecoilValue(loggedInState);

  const openMenu = () => {
    setDrawer(true);
  }

  const navigate = useNavigate();

  /*
  useEffect(() => {
    if (loggedIn === "cliente") {
      navigate('/areadocliente');
    }
  }, [loggedIn]);
*/

  return (
    <Container
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
    >
      <UniaoHomepageIcons />
    </Container>
  )
}

export default UniaoWelcome;