import * as React from 'react';
import { useState, useRef, useEffect } from "react";
import Container from "@mui/material/Container";
import Snackbar from "@mui/material/Snackbar";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { loggedInState } from "./atoms/loggedInState";
// import { mainViewState } from "./atoms/mainViewState";
import { useSetRecoilState } from "recoil";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { apiProdutosState } from "./atoms/apiProdutosState";
import { useNavigate } from "react-router-dom";

const UniaoLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const inputPasswordRef = useRef(null);
  const setApiProdutosState = useSetRecoilState(apiProdutosState);

  const [waitingForApi, setWaitingForApi] = useState(false);

  const navigate = useNavigate();

  const waitForApi = () => {
    setWaitingForApi(true);
  }

  const doneWaitingForApi = () => {
    setWaitingForApi(false);
  }

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [password, setPassword] = useState('');
  const setLoggedIn = useSetRecoilState(loggedInState);
  // const setMainView = useSetRecoilState(mainViewState);

  useEffect(() => {
    console.log("UniaoLogin: useEffect running.");
    inputPasswordRef.current.focus()
  }, []);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validatePassword = () => {
    console.log("UniaoLogin: validatePassword setting password " + password);
    window.localStorage.setItem("uniaoClienteToken", password);
    waitForApi();
    fetch(`${process.env.REACT_APP_API_URL}/userstatus.php`, {
      headers: {
        'uniao-cliente-token': window.localStorage.getItem('uniaoClienteToken')
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log("UniaoLogin: validatePassword userstatus result: " + JSON.stringify(result));
          if (result.user === "cliente" || result.user === "admin") {
            setApiProdutosState([]);
            setLoggedIn(result.user);
            // setMainView('welcome'); // set to welcomecliente if a separate view is needed
            navigate("/");
            fetch(`${process.env.REACT_APP_API_URL}/produtos.php`, {
              headers: {
                'uniao-cliente-token': window.localStorage.getItem('uniaoClienteToken')
              }
            })
              .then(res => res.json())
              .then(
                (result) => {
                  setApiProdutosState(result.produtos);
                },
                (error) => {
                  console.error("UniaoSite: useEffect fetch produtos.php error: " + error);
                }
              )
          } else {
            setLoggedIn("anonimo");
            setOpenSnackbar(true);
            inputPasswordRef.current.focus();
          }
          setPassword('');
          doneWaitingForApi();
        },
        (error) => {
          console.error("UniaoLogin: validatePassword fetch userstatus error: " + error);
          doneWaitingForApi();
        }
      )
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const showCircularProgress = () => {
    if (waitingForApi) {
      return (
        <CircularProgress sx={{ mt: 2 }} />
      )
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 1,
        }}
      >
        <Typography
          variant="h4"
        >
          Login
        </Typography>

        <Typography
          variant="h6"
        >
          Digite a senha de acesso
        </Typography>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password" color="neutral">
            Senha
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            color="neutral"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Senha"
            value={password}
            onChange={handlePasswordChange}
            inputRef={inputPasswordRef}
          />
        </FormControl>
        <Button
          variant='outlined'
          type="submit"
          onClick={validatePassword}
          disabled={password === ''}
          color="neutral"
          sx={{ mt: "0.5rem" }}
        >
          Fazer login
        </Button>
        {showCircularProgress()}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackbar}
          action={action}
          autoHideDuration={3000}
          message="Senha incorreta, tente novamente."
          onClose={() => setOpenSnackbar(false)}
        />
      </Container>
    </form>
  )
}

export default UniaoLogin;