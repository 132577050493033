import { useRecoilValue } from "recoil";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { loggedInState } from "./atoms/loggedInState";

const UniaoClienteProductInfo = (props) => {
  const item = props.item;
  const loggedIn = useRecoilValue(loggedInState);

  if (loggedIn !== "anonimo") {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell size="small">Cx. peq. / gde.</TableCell>
            <TableCell size="small" sx={{ whiteSpace: "nowrap", textAlign: "right" }}>{item.CAIXAPEQ} / {item.CAIXAGRANDE}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small">Dimensões</TableCell>
            <TableCell size="small" sx={{ textAlign: "right" }}>{item.DIMENSAO}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small">CV</TableCell>
            <TableCell size="small" sx={{ whiteSpace: "nowrap", textAlign: "right" }}>{item.CV}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small">Disponível</TableCell>
            <TableCell size="small" sx={{ whiteSpace: "nowrap", textAlign: "right" }}>{item.DISPONIVEL}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small">Reservado</TableCell>
            <TableCell size="small" sx={{ whiteSpace: "nowrap", textAlign: "right" }}>{item.RESERVADO}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small">NCM</TableCell>
            <TableCell size="small" sx={{ textAlign: "right" }}>{item.NCM}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  } else {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell size="small">Cx. peq. / gde.</TableCell>
            <TableCell size="small" sx={{ whiteSpace: "nowrap", textAlign: "right" }}>{item.CAIXAPEQ} / {item.CAIXAGRANDE}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small">Dimensões</TableCell>
            <TableCell size="small" sx={{ textAlign: "right" }}>{item.DIMENSAO}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export default UniaoClienteProductInfo;