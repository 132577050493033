import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { productModalState } from "./atoms/productModalState";
import { useRecoilState, useRecoilValue } from "recoil";
import Magnifier from "react-magnifier";
import CircularProgress from "@mui/material/CircularProgress";
import { singleProductSpinnerState } from "./atoms/singleProductSpinnerState";
import { Button, CardContent, Typography, useMediaQuery } from "@mui/material";
import { useRef, useCallback } from "react";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { useParams } from "react-router-dom";
import { apiProdutosState } from "./atoms/apiProdutosState";
import UniaoClienteProdutoInfo from "./UniaoClienteProdutoInfo";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";

const UniaoProduct = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [productModal, setProductModal] = useRecoilState(productModalState);
  const [singleProductSpinner, setSingleProductSpinner] = useRecoilState(singleProductSpinnerState);
  const { codigo } = useParams();
  const produtos = useRecoilValue(apiProdutosState);
  const produto = produtos.filter((produto) => produto.CODIGO === codigo.toString())[0];

  const handleClose = () => {
    setProductModal(false);
  }

  const openModal = () => {
    setProductModal(true);
  }

  const showSpinner = () => {
    if (singleProductSpinner) {
      return <CircularProgress sx={{ m: 6 }} />;
    }
    return <></>;
  }

  const imgRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const imageWrapper = (codigo) => {
    if (produto) {
      if (isLargeScreen) {
        return (
          <div style={{ display: "flex", justifyContent: "start" }}>
            <Magnifier
              src={`/fotos20230320/${codigo}.jpg`}
              alt={codigo}
              mgWidth={330}
              mgHeight={330}
              mgShowOverflow={false}
              style={{ maxHeight: "85vh", maxWidth: "85vw" }}
            />
            <Card sx={{ width: "22rem", height: "100%", m: 3 }}>
              <CardContent>
                <Typography>
                  {produto.PRODUTO}
                </Typography>
                <UniaoClienteProdutoInfo item={produto} />
              </CardContent>
            </Card>
          </div>
        )
      } else {
        return (
          <>
            <Button onClick={openModal} variant="outlined">
              Mais informações
            </Button>
            <QuickPinchZoom onUpdate={onUpdate}
              verticalPadding={1000}
              horizontalPadding={1000}>
              <div ref={imgRef} style={{ width: '95vw', height: 'calc(100vh - 230px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img style={{ width: "100%" }} src={`/fotos20230320/${codigo}.jpg`} alt={codigo} />
              </div>
            </QuickPinchZoom>
            <Dialog
              onClose={handleClose}
              open={productModal}
              PaperProps={{
                sx: {
                  minHeight: '30vh'
                }
              }}
            >
              <DialogTitle
                sx={{ display: "flex", justifyContent: "center", fontSize: '1.5rem' }}
              >
                {codigo}
                <IconButton
                  aria-label="Fechar"
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#aaa"
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0 10px 25px 10px" }}>
                <Typography>
                  {produto.PRODUTO}
                </Typography>
                <UniaoClienteProdutoInfo item={produto} />
              </div>
            </Dialog>
          </>
        )
      }
    }
  }

  const isLargeScreen = useMediaQuery('(min-width: 600px');

  const caption = () => {
    if (isLargeScreen) {
      return <></>
    } else {
      return (
        <div>
          <Typography sx={{ mb: 6 }}>
            Estique a imagem com dois dedos
          </Typography>
        </div>
      )
    }
  }
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography variant="h5" sx={{ mt: 2 }}>
        {codigo}
      </Typography>

      <Box
        sx={{
          minWidth: "220px", minHeight: "100px", display: "flex", flexDirection: "column",
          justifyContent: "center", alignItems: "center"
        }}
      >
        {imageWrapper(codigo)}
        {caption()}
        <img
          src={`/fotos20230320/${codigo}.jpg`}
          alt=""
          style={{ display: 'none' }}
          onLoad={() => setSingleProductSpinner(false)}
        />
        {showSpinner()}
      </Box>
    </div>
  );
}

export default UniaoProduct;