import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { drawerState } from './atoms/drawerState';
import { useRecoilState } from 'recoil';
import { toggleState } from './stateUtils';
import UniaoMenu from './UniaoMenu';

const UniaoDrawer = () => {
  const [drawer, setDrawer] = useRecoilState(drawerState);

  return (
    <Drawer
      anchor="left"
      open={drawer}
      onClose={toggleState("UniaoDrawer drawer", setDrawer, drawer)}
    >
      <UniaoMenu />
      <Box
        sx={{ mb: '20rem' }}
      >
      </Box>
    </Drawer>
  )
}

export default UniaoDrawer;