import { categoryState } from './atoms/categoryState';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useRecoilValue } from "recoil";

const UniaoCategoryHeader = () => {
  const category = useRecoilValue(categoryState);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight={48}
      sx={{ mt: '1rem', mb: 0 }}
    >
      <Typography
        variant="h4"
        align="center"
      >
        {category.name}
      </Typography>
    </Box>
  )
}

export default UniaoCategoryHeader;