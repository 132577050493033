import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { gridState } from "./atoms/gridState";
import { categoryState } from "./atoms/categoryState";
import { drawerState } from "./atoms/drawerState";
import { loggedInState } from "./atoms/loggedInState";
import { menuState } from "./selectors/menuState";
import PhoneIcon from '@mui/icons-material/Phone';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import TableViewIcon from '@mui/icons-material/TableView';
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import UniaoMenuCategories from "./UniaoMenuCategories";

const UniaoMenu = () => {
  const setGrid = useSetRecoilState(gridState);
  const setCategory = useSetRecoilState(categoryState);
  const menu = useRecoilValue(menuState);
  const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
  const setDrawer = useSetRecoilState(drawerState);

  const navigate = useNavigate();

  const openTabela = (event) => {
    event.stopPropagation();
    console.log(`UniaoMenu: openTabela`);
    navigate("/planilha/");
    setDrawer(false);
  }

  const openContactInfo = () => {
    console.log("UniaoMenu: openContactInfo");
    setDrawer(false);
    navigate("/contato/");
  }

  const openSearch = () => {
    console.log("UniaoMenu: openSearch");
    setGrid([]);
    setCategory({
      type: "search",
      id: 0,
      name: "Busca",
    });
    setDrawer(false);
    navigate("/busca/");
  }

  const openLogin = () => {
    console.log("UniaoMenu: openLogin");
    setDrawer(false);
    navigate("/login/");
  }

  const logout = () => {
    console.log("UniaoMenu: logout");
    window.localStorage.setItem("uniaoClienteToken", "");
    setDrawer(false);
    setLoggedIn("anonimo");
    navigate("/");
  }

  const showLoginLink = () => {
    if (loggedIn === "anonimo") {
      console.log("UniaoMenu: not logged in");
      return (
        <Box
          sx={{ pl: '1rem', py: '0.8rem;', borderTop: 1, borderColor: '#dcdcdc', display: 'flex', alignItems: 'center' }}
          onClick={openLogin}
        >
          <LoginIcon />
          <Typography
            sx={{ pl: '0.6rem' }}
          >
            Login de Cliente
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{ background: "linear-gradient(90deg, rgba(220,220,220,1) 0%, rgba(255,255,255,1) 20%, rgba(191,191,191,1) 100%)", pl: '1rem', py: '0.8rem;', borderTop: 1, borderColor: '#dcdcdc', display: 'flex', alignItems: 'center' }}
          onClick={logout}
        >
          <LogoutIcon />
          <Typography
            sx={{ pl: '0.6rem' }}
          >
            Logout
          </Typography>
        </Box>
      )
    }
  }

  const showLoadingMenu = () => {
    if (menu.length === 0) {
      return (
        <Box
          sx={{ background: "linear-gradient(90deg, rgba(220,220,220,1) 0%, rgba(255,255,255,1) 20%, rgba(191,191,191,1) 100%)", pl: '1rem', py: '0.8rem;', borderTop: 1, borderColor: '#dcdcdc', display: 'flex', alignItems: 'center' }}
        >
          <CircularProgress color="secondary"
            size='1rem'
          />
          <Typography
            sx={{ pl: '0.6rem' }}
          >
            Carregando, aguarde por favor...
          </Typography>
        </Box>
      )
    }
  }

  const showTabelaLink = () => {
    if (loggedIn === "cliente") {
      return (
        <Box
          sx={{ background: "linear-gradient(90deg, rgba(220,220,220,1) 0%, rgba(255,255,255,1) 20%, rgba(191,191,191,1) 100%)", pl: '1rem', py: '0.8rem;', borderTop: 1, borderColor: '#dcdcdc', display: 'flex', alignItems: 'center' }}
          onClick={openTabela}
        >
          <TableViewIcon />
          <Typography
            sx={{ pl: '0.6rem' }}
          >
            Planilha
          </Typography>
        </Box>
      )
    } else {
      return <></>
    }
  }

  return (
    <div style={{ background: "linear-gradient(90deg, rgba(220,220,220,1) 0%, rgba(255,255,255,1) 20%, rgba(191,191,191,1) 100%)" }}>
      {showLoginLink()}

      <Box
        sx={{ background: "linear-gradient(90deg, rgba(220,220,220,1) 0%, rgba(255,255,255,1) 20%, rgba(191,191,191,1) 100%)", pl: '1rem', py: '0.8rem;', borderTop: 1, borderColor: '#dcdcdc', display: 'flex', alignItems: 'center' }}
        onClick={openContactInfo}
      >
        <PhoneIcon />
        <Typography
          sx={{ pl: '0.6rem' }}
        >
          Contato
        </Typography>
      </Box>

      {showTabelaLink()}

      <Box
        sx={{ background: "linear-gradient(90deg, rgba(220,220,220,1) 0%, rgba(255,255,255,1) 20%, rgba(191,191,191,1) 100%)", pl: '1rem', py: '0.8rem;', borderTop: 1, borderColor: '#dcdcdc', display: 'flex', alignItems: 'center' }}
        onClick={openSearch}
      >
        <SearchIcon />
        <Typography
          sx={{ pl: '0.6rem' }}
        >
          Busca
        </Typography>
      </Box>

      {showLoadingMenu()}
      <UniaoMenuCategories />
    </div>
  )
}

export default UniaoMenu;