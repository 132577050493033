import { categoryState } from "./atoms/categoryState";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { drawerState } from "./atoms/drawerState";
import { useRecoilState, useSetRecoilState } from "recoil";
import { toggleState } from "./stateUtils";

const UniaoCategoryIcon = (props) => {
  const iconFilename = props.iconFilename;
  const name = props.name;
  const target = props.target;

  const [drawer, setDrawer] = useRecoilState(drawerState);

  const setCategory = useSetRecoilState(categoryState);
  const navigate = useNavigate();

  const updateCategory = () => {
    if (target === "menu") {
      console.log("UniaoCategoryIcon: updateCategory: caught menu");
      toggleState("abc", setDrawer, drawer)(); // function was meant to be called without redirection in onClick context
    } else {
      setCategory({ name: name });
      navigate(`/categorias/${target}`);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} onClick={updateCategory}>
      <div className="uniao-category-icon-holder">
        <img src={`/homepage/${iconFilename}`} alt={iconFilename} width="120" />
      </div>
      <div className="uniao-category-icon-name">
        <Typography variant="h6" align="center">
          {name}
        </Typography>
      </div>
    </div>
  );
}

export default UniaoCategoryIcon;