import { useRecoilValue } from "recoil";
import { apiProdutosState } from "./atoms/apiProdutosState";
import { useEffect } from "react";
import UniaoProductGrid from "./UniaoProductGrid";
import Typography from '@mui/material/Typography';

const UniaoReposicao = () => {
  const produtos = useRecoilValue(apiProdutosState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let gridItems = [];
  gridItems = produtos.filter((produto) => produto.REPOSICAO.trim() !== "");

  return (
    <>
      <Typography
        variant="h4"
        align="center"
      >
        Reposição
      </Typography>
      <UniaoProductGrid gridItems={gridItems} />
    </>
  );
}

export default UniaoReposicao;