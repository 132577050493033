import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useSetRecoilState } from "recoil";
import { drawerState } from "./atoms/drawerState";
import UniaoHomepageIcons from "./UniaoHomepageIcons";

const UniaoWelcomeCliente = () => {
  const setDrawer = useSetRecoilState(drawerState);

  const openMenu = () => {
    setDrawer(true);
  }

  return (
    <Container
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
    >
      <Typography
        variant="h3"
        sx={{ my: 4 }}
        align="center"
      >
        Área do Cliente
      </Typography>

      <UniaoHomepageIcons />

    </Container>
  )
}

export default UniaoWelcomeCliente;