import { Card, useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

const UniaoContato = () => {
  const loadGoogleMaps = () => {
    window.open("https://goo.gl/maps/UJDtX6Usm5HmuQR48");
  }

  const isLargeScreen = useMediaQuery('(min-width: 600px');

  const mapWrapper = () => {
    if (isLargeScreen) {
      return (
        <div>
          <iframe title="mapa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.1281153652953!2d-46.6317594493535!3d-23.527894066167487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce58f528372e07%3A0x29aa8680ebcf947c!2sR.%20Alfredo%20Maia%2C%20429%20-%20Luz%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2001106-010!5e0!3m2!1sen!2sbr!4v1677195686021!5m2!1sen!2sbr"
            width="1200" height="600" style={{ border: 0, marginTop: '25px', marginBottom: '25px' }} allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade" />
        </div>
      );
    } else {
      return (
        <div>
          <iframe title="mapa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.1281153652953!2d-46.6317594493535!3d-23.527894066167487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce58f528372e07%3A0x29aa8680ebcf947c!2sR.%20Alfredo%20Maia%2C%20429%20-%20Luz%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2001106-010!5e0!3m2!1sen!2sbr!4v1677195686021!5m2!1sen!2sbr"
            width="400" height="400" style={{ border: 0, marginTop: '25px', marginBottom: '25px' }} allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade" />
        </div>
      );
    }
  }

  return (
    <Container
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', my: 1 }}
    >
      <Typography
        variant="h4"
      >
        Contato
      </Typography>
      <Card sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MapsHomeWorkIcon />
          <Typography variant="h6" sx={{ ml: 2, color: "#2a1" }}
            onClick={loadGoogleMaps}
          >
            {process.env.REACT_APP_ENDERECO}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PhoneIcon />
          <Typography variant="h6" sx={{ ml: 1 }}>
            {process.env.REACT_APP_TELEFONE_1}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PhoneIphoneIcon />
          <Typography variant="h6" sx={{ ml: 1 }}>
            {process.env.REACT_APP_TELEFONE_2}
          </Typography>
        </Box>
      </Card>
      {mapWrapper()}
    </Container>
  )
}

export default UniaoContato;
