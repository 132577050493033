import { Typography } from "@mui/material";

const UniaoFooter = () => {
  return (
    <div
      style={{ position: "absolute", bottom: 0, padding: "0.45rem", backgroundColor: '#fff' }}
    >
      <Typography>
        &copy;&nbsp;{new Date().getFullYear()}&nbsp;União&nbsp;Brindes
      </Typography>
    </div>
  )
}

export default UniaoFooter;