import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import { drawerState } from "./atoms/drawerState";
import { toggleState } from "./stateUtils";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
// import { mainViewState } from "./atoms/mainViewState";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import TableViewIcon from '@mui/icons-material/TableView';
import { Link } from "react-router-dom";
import { loggedInState } from "./atoms/loggedInState";
import "./App.css";

const UniaoAppBar = () => {
  const [drawer, setDrawer] = useRecoilState(drawerState);
  const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);

  // const setMainView = useSetRecoilState(mainViewState);
  const navigate = useNavigate();

  const openWelcome = () => {
    navigate("/");
  }

  const logout = () => {
    console.log("UniaoMenu: logout");
    window.localStorage.setItem("uniaoClienteToken", "");
    setDrawer(false);
    setLoggedIn("anonimo");
    // setMainView("welcome");
    navigate("/");
  }

  const userLinks = () => {
    if (loggedIn === "cliente") {
      return (
        <>

          <Link to="/" className="toolbar-link" onClick={logout}>
            <LogoutIcon sx={{ mr: 1 }} />
            Logout
          </Link>

          <Link to="/planilha/" className="toolbar-link">
            <TableViewIcon sx={{ mr: 1 }} />
            Planilha
          </Link>
        </>
      );
    } else {
      return (
        <>
          <Link to="/login/" className="toolbar-link">
            <LoginIcon sx={{ mr: 1 }} />
            Login
          </Link>
        </>
      );
    }
  }

  const isLargeScreen = useMediaQuery('(min-width: 600px');

  const contentWrapper = () => {
    if (isLargeScreen) {
      return (
        <Toolbar
          sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "1rem" }}
        >
          <Link to="/">
            <img src="/logos/ulogo_green_80.png" alt="U Logo" style={{ margin: "10px" }} />
          </Link>

          <Typography
            variant="h4"
            component="div"
          >
            <Link to="/">União Brindes</Link>
          </Typography>

          <Typography
            variant="h6"
            component="div"
            sx={{ display: "flex" }}
          >
            {userLinks()}
            <Link to="/busca/" className="toolbar-link">
              <SearchIcon sx={{ mr: 1 }} />
              Busca
            </Link>

            <Link to="/contato/" className="toolbar-link">
              <PhoneIcon sx={{ mr: 1 }} />
              Contato
            </Link>
          </Typography>
        </Toolbar>
      )
    } else {
      return (
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="Abrir menu"
            sx={{ mr: 2 }}
            onClick={toggleState("UniaoAppBar IconButton", setDrawer, drawer)}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <img src="/logos/ulogo_green_30.png" alt="U Logo" style={{ margin: "10px" }} />
          </Link><Typography
            variant="h6"
            noWrap
            component="div"
            color="#2a1"
            onClick={openWelcome}
          >
            União Brindes
          </Typography>
        </Toolbar >
      )
    }
  }

  return (
    <AppBar position="sticky" color="inherit" elevation={0}>
      {contentWrapper()}
    </AppBar>
  )
}

export default UniaoAppBar;