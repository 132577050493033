import { RecoilRoot } from "recoil";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UniaoSite from "./UniaoSite";
require("typeface-roboto-condensed");

const theme = createTheme({
  palette: {
    primary: {
      main: "#1d810d",
    },
    neutral: {
      main: '#393',
      contrastText: '#999',
    }
  },
  typography: {
    fontFamily: [
      'Roboto Condensed',
      'sans-serif'
    ].join(","),
  }
});

const App = () => { 
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <UniaoSite />
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default App;
