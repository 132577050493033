import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import UniaoCategoryGrid from './UniaoCategoryGrid';
import UniaoProduct from './UniaoProduct';
import UniaoCategoryHeader from './UniaoCategoryHeader';
import UniaoLogin from './UniaoLogin';
import UniaoWelcomeCliente from './UniaoWelcomeCliente';
import UniaoContato from './UniaoContato';
import UniaoSearch from './UniaoSearch';
import UniaoTabela from './UniaoTabela';
import UniaoPromocao from './UniaoPromocao';
import UniaoReposicao from './UniaoReposicao';

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "login/",
        element: <UniaoLogin />,
      },
      {
        path: "areadocliente/",
        element: <UniaoWelcomeCliente />,
      },
      {
        path: "contato/",
        element: <UniaoContato />,
      },
      {
        path: "busca/",
        element: <UniaoSearch />,
      },
      {
        path: "busca/:urlquery",
        element: <UniaoSearch />,
      },
      {
        path: "planilha/",
        element: <UniaoTabela />,
      },
      {
        path: "categorias/:urlname",
        element: <><UniaoCategoryHeader /><UniaoCategoryGrid /></>,
      },
      {
        path: "produtos/:codigo",
        element: <UniaoProduct />
      },
      {
        path: "promocao/",
        element: <UniaoPromocao />,
      },
      {
        path: "reposicao/",
        element: <UniaoReposicao />,
      }
    ]
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
